import React from "react";
import {Link} from "react-router-dom";
import Logo from "../images/logopolyage.jpg";

function Navbar(props){
    return(
<>
<div className="fixed-top">
  <center>
<div class="p-2 mb-0 bg-secondary text-white"> Sofa FLAT 20% Off</div>
</center>

<nav className="navbar navbar-expand-lg navbar-light bg-light  shadow">
  <div className="container">
    <Link to="/" className="navbar-brand fw-bold  fs-4" >
       <img src={Logo} className="img-fluid"  width="130" height="17" alt="Logo"/>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
      <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" > <b style={{color: "Darkblue"}}> HOME </b> </Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-link active" aria-current="page" > <b style={{color: "Darkblue"}}>ABOUT US</b></Link>
        </li>
      
    
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" style={{color: "Darkblue"}} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        ALL PRODUCTS
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
    
            <li><Link to="/Sofatypes" className="dropdown-item" >Sofa</Link></li>
            <li><Link to="/centre_table" className="dropdown-item" >Centre Table</Link></li>
            <li><Link to="/chair" className="dropdown-item" >Chairs</Link></li>
            <li><Link to="/bedtype" className="dropdown-item" >Beds</Link></li>
            <li><Link to="/dressing-table" className="dropdown-item" >Dressing Tables</Link></li>
            
          
           
            <li><Link to="/cupboard" className="dropdown-item" >Cupboards</Link></li>
            {/* <li><hr className="dropdown-divider"/></li>
            <li><Link to="/Allproduct" className="dropdown-item">All Product Live </Link></li> */}
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/offer" className="nav-link active" aria-current="page" > <b style={{color: "Darkblue"}}>OFFERS</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-link active" aria-current="page" > <b style={{color: "Darkblue"}}>CONTACT</b></Link>
        </li>
        
      </ul>
      <a href="tel:  033-22159838"  className="btn btn-outline-dark">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> 033-22159838*         <div className="spinner-grow text-primary spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div> </a> 

    </div>
  </div>
</nav>
</div>

</>

    );
}

export default Navbar;