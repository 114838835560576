import React, { useState, useEffect } from "react";
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Navbar from "../inc/Navbar";
import Net from "../images/page2.jpg";
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from 'react-whatsapp';
import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";

function Dressingtable(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(15);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [dressingtable, setDressingtable] = useState([])
      const navigate = useNavigate()
  
      const dressingtableCollectionRef = collection(db, "dressingtable");
      useEffect(() => {
  
          const getDressingtable = async () => {
              const data = await getDocs(dressingtableCollectionRef);
              setDressingtable(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getDressingtable()
      }, [])
    return (
      <>

              <Navbar/>
              <br/>
              <br/>
              <br/>
                <div className="section footer bg-light text-dark">
                  <br/>
                  <br/>
                  <center>
                 <h2 style={{fontSize: "3rem", color: "Darkblue"}}> High quality Dressing Table | Available Here</h2></center>
                  <br/>
                  <br/>
                </div>
            {/* <img src={Net} class="img-fluid" width="1350" height="600"  alt="Net"/> */}

            <ScrollToTop smooth top="100" color="purple" />

<br/>
<br/>



<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder=" Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {dressingtable.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.dis.toLowerCase().includes(searchTerm.toLowerCase())
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((website) => { return (
       
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={website.img}   alt="Dressing_Table" />
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{website.title}</b></h6>
                                <p className="card-text">{website.dis}</p>
                                <a href="tel: 033-22159838" className="btn btn-dark mb-2">Want to know more? Call Now  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-plus-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"/>
</svg></a> <br/>
                                <ReactWhatsapp number="+91 9073595605" className="btn btn-outline-dark mb-3"   message= "I am interested in buying Poly Age Dressing"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg>  Whatsapp Enquiry </ReactWhatsapp> <br/>

                               
<button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/dressing-table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/dressing-table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/dressing-table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/dressing-table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/dressing-table" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                              
                                       

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={centretable.length}
                /> */}
                            </div>
                            </div>


              </>


);
}



export default Dressingtable;