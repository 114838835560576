import React from 'react';

import Navbar from "./components/inc/Navbar";
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import Home from "./components/pages/Home";
import Allp from "./components/pages/Allp";
import About from "./components/pages/About";
import Chair from "./components/pages/Chair";
import Footer from "./components/inc/Footer";
import Sofa from "./components/pages/Sofa";
import Contact from "./components/pages/Contact";
import Catalogue from "./components/pages/Catalogue";
import PaginationOld from "./components/pages/PaginationOld";
import AddBook from './components/AddBook';
import Sofatype from "./components/pages/Sofatype";
import Club from "./components/pages/Club";
import Ctable from "./components/pages/Ctable";
import Offer from "./components/pages/Offer";
import Bedtype from "./components/pages/Bedtype";
import Dressing from "./components/pages/Dressing";
import Cupboard from "./components/pages/Cupboard";
import Singlebed from "./components/pages/Singlebed";
import Kingbed from "./components/pages/Kingbed";
import Queen from "./components/pages/Queen";

function App() {
  return (


    <Router>
      <div>
      
          <Routes>
          <Route axact path="/" element={<Home/>}/>
          <Route axact path="/Allproduct" element={<Allp/>}/>
          <Route axact path="/about" element={<About/>}/>
          <Route axact path="/chair" element={<Chair/>}/>
          <Route axact path="/sofa" element={<Sofa/>}/>
          <Route axact path="/contact" element={<Contact/>}/>
          <Route axact path="/Catalogue" element={<Catalogue/>}/>
          <Route axact path="/PaginationOld" element={<PaginationOld/>}/>
          {/* <Route axact path="/BestSeller" element={<BestSeller/>}/> */}
          <Route axact path="/club-sofa" element={<Club/>}/>
        
          <Route axact path="/Sofatypes" element={<Sofatype/>}/>
          <Route axact path="/centre_table" element={<Ctable/>}/>
          <Route axact path="/offer" element={<Offer/>}/>
          <Route axact path="/bedtype" element={<Bedtype/>}/>
          <Route axact path="/dressing-table" element={<Dressing/>}/>
          <Route axact path="/cupboard" element={<Cupboard/>}/>
          <Route axact path="/singlebed" element={<Singlebed/>}/>
          <Route axact path="/kingbed"  element={<Kingbed/>}/>
          <Route axact path="/queenbed"  element={<Queen/>}/>
          

          </Routes>
  </div>
  <Footer/>
    </Router>
   
  );
}

export default App;
