import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";




function Allp (props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(3);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [allproduct, setAllproduct] = useState([])
      const navigate = useNavigate()
  
      const allproductCollectionRef = collection(db, "allproduct");
      useEffect(() => {
  
          const getAllproduct = async () => {
              const data = await getDocs(allproductCollectionRef);
              setAllproduct(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getAllproduct()
      }, [])
    return (
      
<>
<Navbar/>
<br/>
<br/>
<br/>
<ScrollToTop smooth top="100" color="purple" />

<br/>
<br/>
<h1 style={{textAlign: "center", color: "Orange"}}><b>"Poly Age India's No1 Trusted Brand"</b></h1>
<h5 style={{textAlign: "center", color: "Gold"}}><b>Search what kind of sofa & chair you want</b></h5>

<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Office & Home Furniture, Search what kind of sofa, Chair & Table." name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {allproduct.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.dis.toLowerCase().includes(searchTerm.toLowerCase())
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((website) => { return (
       
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={website.img}   alt="Singer" />
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{website.title}</b></h6>
                                <p className="card-text">{website.dis}</p>
                                <div className="d-flex justify-content-between align-items-center">
                              
                                       

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={allproduct.length}
                />
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Allp;