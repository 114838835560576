import React from 'react'
import {Link} from "react-router-dom"
import Mapg from '../pages/Mapg';
import Slider from "../inc/Slider";
import Navbar from "../inc/Navbar";
import Netback from "../images/netback.jpg";
import card2 from "../images/card2.jpg";
import Card3 from "../images/card3.jpg";

import Card4 from "../images/newc.jpg";

import Sofa1 from "../images/sofap.jpg";
import Chair2 from "../images/ccc.jpg";
import Table from "../images/table.jpg";
import Cupboard from "../images/cup.jpg";
import Dressingt from "../images/dre.jpg";
import Bed from "../images/bedm.jpg";
import { Typewriter } from 'react-simple-typewriter'

import ScrollToTop from "react-scroll-to-top";
import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";
import './Home.css'

function Home(props) {
  return (
    <div>
      
      <Navbar/>
      <br/>
      <br/>
      <br/>
   
    
      <div className="album py-1">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
              
                    <div className="card-body">
            
                           
                            <h4 className="card-text"> POLY AGE KOLKATA | FURNITURE </h4>
                    
                    </div>

                  
                </div>
                </div>
                </div>
                </div>
      <ScrollToTop smooth  top="120" color="blue"/>

      
 
       
   
      <Slider/>
      <div className="section footer bg-secondary text-white">
      <div className="album py-2">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
      <h3 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "light"}}> <b>   <span style={{color: "white", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={90}
    deleteSpeed={50}
    delaySpeed={1000}
    words={[ "L SHAPE SOFA", " ENGLISH CLUB SOFA", "OFFICE CHAIR", "NETBACK CHAIR", "KING BED", "QUEEN BED","SINGLE BED", "DRESSING TABLE", "ALL TYPE OF TABLE"]}
    />
</span>
</b></h3>
</div>
</div>
</div>
</div>

      </div>
      <div className="section footer bg-dark text-white">
                              <br/>
                              
                            
                                 <div className="album py-1">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
              
                    <div className="card-body">
            
              
                            <p className="card-text mb-1" > <small>EXPLORE OUR INDIA RANGE OF CATALOGUE</small></p>
                                 <h2>Download Catalogue</h2>
                    
                    </div>

                  
                </div>
                <div className="col-md-5">
              
                    <div className="card-body">
            
                           
                            <p className="card-text mb-1" > <small>If you'd like to view our latest product catalogue you can download it here.</small></p>
                            <Link to="/Catalogue">
                            <button type="button" class="btn btn-outline-light">Download Catalogue</button>
                            </Link>


                    </div>

                  
                </div>
                </div>
                </div>
                </div>
                              <br/>
                              
                              </div> 
{/* <img src={ Ima} class="img-fluid" width="1350" height="600"  alt=" Ima"></img> */}


           <br/>
  
                            <div className="album py-2">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
              
                    <div className="card-body">
            
                    <h3 className="card-text mb-1">     Top furniture categories</h3>
                           
                            <p className="card-text">Genuine Product</p>
                    
                    </div>

                  
                </div>
                </div>
                </div>
                </div>
                <div className="album py-4">
        
        <div className="container " >

            <div className="row  ">
       
            <div className="col-md-4 mt-6  " >
                        <div className="card mb-4 border-White box shadow  ">
                            <Link to="/Sofatypes">
                           
                        <img src={Sofa1} className="card-img-top "   alt="Sofa1" />
                      
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>Sofa - L Shape, English & Club</b></h6>
                                <Link to="/Sofatypes">
                                <p className="card-text mb-3">Explore Now</p>   </Link>
                                <button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/Sofatypes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/Sofatypes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/Sofatypes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/Sofatypes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/Sofatypes" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6  " >
                        <div className="card mb-4 border-White box shadow  ">
                            <Link to="/bedtype">
                           
                        <img src={Bed} className="card-img-top "   alt="Bed" />
                      
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>Bed - King, Queen, Single</b></h6>
                                <Link to="/bedtype">
                                <p className="card-text mb-3">Explore Now</p>   </Link>
                                <button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/bedtype" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/bedtype" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/bedtype" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/bedtype" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/bedtype" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6  " >
                        <div className="card mb-4 border-White box shadow  ">
                            <Link to="/dressing-table">
                           
                        <img src={Dressingt} className="card-img-top "   alt="Dressingt" />
                      
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>Dressing table</b></h6>
                                <Link to="/dressing-table">
                                <p className="card-text mb-3">Explore Now</p>   </Link>
                                <button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/dressing-table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/dressing-table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/dressing-table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/dressing-table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/dressing-table" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6  " >
                        <div className="card mb-4 border-White box shadow  ">
                            <Link to="/chair">
                           
                        <img src={Chair2} className="card-img-top "   alt="Chair Poly Age" />
                      
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>Chair</b></h6>
                                <Link to="/chair">
                                <p className="card-text mb-3">Explore Now</p>   </Link>
                                <button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/chair" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/chair" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/chair" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/chair" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/chair" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6 " >
                        <div className="card mb-4 border box  shadow ">
                          <Link to="/cupboard">
                        <img src={Cupboard} className="card-img-top "   alt="Cupboard poly age" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b>Cupboard</b></h6>
                                <Link to="/cupboard">
                                <p className="card-text mb-3"> Explore Now</p></Link>
                                <button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/cupboard" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/cupboard" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/cupboard" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/cupboard" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/cupboard" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
<div className="d-flex justify-content-between align-items-center">
                                

               
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border box  shadow">
                          <Link to="/centre_table">
                        <img src={Table} className="card-img-top"   alt="Table" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Table</b></h6>
                                <Link to="/centre_table">
                                <p   className="card-text mb-3">Explore Now</p>
                                </Link>
                                <button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/centre_table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/centre_table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/centre_table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/centre_table" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/centre_table" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>

                            <h1 style={{color: "Gray"}}><center>#India's Best Netback Chair </center></h1>
                           
                  
                 
                           <div className="first">
                           {/* <img src={Brand} class="img-fluid " width="1350" height="600"  alt="Brand"></img> */}
                           </div>
       
                          
       
       <div className="album py-2">
               
               <div className="container">
       
                   <div className="row">
              
                   <div className="col-md-3 mt-6  " >
                               <div className="card mb-4 border-White box first  ">
                                   <Link to="/chair">
                                  
                               <img src={Netback} className="card-img-top "   alt="Netback" />
                             
                               </Link>
                                   <div className="card-body">
                                       
                                       <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>NetBack Chair</b></h6>
                                      
                                       <p className="card-text mb-3">Explore Now</p>
                                     
                                       <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg"  style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg"  style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg"  style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-half" viewBox="0 0 16 16">
         <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
       </svg>
       
       
                                       <div className="d-flex justify-content-between align-items-center">
                                      
                                              
                               
                                     
                                   </div>
                                 
                                   </div>
                                   </div>
                                      
                                   </div>
                                   <div className="col-md-3 mt-6 " >
                               <div className="card mb-4 border box  first">
                                  <Link to="/chair">
                                  
                               <img src={card2} className="card-img-top "   alt="card2" /></Link>
                                   <div className="card-body">
                                       <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b>NetBack Chair</b></h6>
                                       <p className="card-text mb-3"> Explore Now</p>
                                       <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
         <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
       </svg>  <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
         <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
       </svg>
       
       <div className="d-flex justify-content-between align-items-center">
                                       
                                  
                                   </div>
                                   </div>
                                   </div>
                                      
                                   </div>
                                   {/* style={{textAlign: "center"}} */}
                                   <div className="col-md-3 " >
                               <div className="card mb-4 border box  first ">
                               <Link to="/chair">
                               <img src={Card3} className="card-img-top"   alt="Card3" /></Link>
                                   <div className="card-body">
                                       <h6 style={{ color: "DarkSlateBlue		"}} className="card-text"><b>Office Chair</b></h6>
                                       <p   className="card-text">Explore Now</p>
                                       <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
         <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
       </svg>
                                       <div className="d-flex justify-content-between align-items-center">
                                          
                                      
                                      
                                  
                             
                                            
                                             
                                              
                                     
                                   </div>
                                   </div>
                                   </div>
                                      
                                   </div>
                                   <div className="col-md-3 mt-6 " >
                               <div className="card mb-4 border box  first  ">
                               <Link to="/chair">
                               <img src={Card4} className="card-img-top "   alt="Card4" /></Link>
                                   <div className="card-body">
                                       <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b>Office Chair</b></h6>
                                       <p className="card-text"> Explore Now</p>
                                       <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
         <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
       </svg>  <svg xmlns="http://www.w3.org/2000/svg"  style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star-half" viewBox="0 0 16 16">
         <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
       </svg> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "Gold	"}} width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
         <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
       </svg>
       <div className="d-flex justify-content-between align-items-center">
                                       
                                  
                                   </div>
                                   </div>
                                   </div>
                                      
                                   </div>
                                   </div>
                                   </div>
                                   </div>        
         <div className="album py-5">
        
        <div className="container">
        <div className="card mb-4 box-shadow">
            <div className="row">
           
                <div className="col-md-13 ">
              
                    <div className="card-body">
                    {/* <h4 className="card-text">Your data is 100% secure here</h4>
                            <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
</svg> Uniaone Family does not sell or share your data with any third party </p>
<p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
</svg> <small> Uniaone Family follows stringent policies so that data isn’t compromised at any step</small></p>
<p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
</svg>  We have a responsibility to make your data secure</p> */}
 <h4 style={{textAlign: "center", color: "Gold"}} className="card-text"><center>OUR CUSTOMER FEEDBACK</center></h4>
 <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="10000">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "Gray"}}> The sofa quality here is very good.
</h6>
        <p className="btn btn btn-lg px-4 gap-3">Ayushman Banerjee (Businessman)</p>
        </div>
     
    </div>
    <div className="carousel-item" data-bs-interval="2000">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "Gray"}}>Bought chair for my office from Poly Age, very nice quality</h6>
        <p className="btn btn btn-lg px-4 gap-3">Moumita Agarwala (Entrepreneur)</p>
        </div>
      
    </div>
    <div class="carousel-item">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "Gray"}}>I am very happy after using Poly Age Company's sofa.</h6>
        <p  className="btn btn btn-lg px-4 gap-3">Sourav Sarkar (Govt. Services)</p>
        </div>
     
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

                            <div className="d-flex justify-content-between align-items-center">
                               
                                
                          </div>

                   
                      
                    </div>
                    </div>

                  
                </div>
                </div>
                </div>
                </div>
    <hr/>

   
                            <Mapg/>

    </div>
  );
}



export default Home;
