import React from "react";
import Navbar from "../inc/Navbar";
import {Link } from "react-router-dom";
import Sofa1 from "../images/lshapesofa.jpg";
import Chair2 from "../images/club.jpg";
import { 
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
     FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,
  
     LinkedinShareButton,
     LinkedinIcon,
     TwitterIcon,
  }  from "react-share";

function Sofatype(props) {
    return(
        <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/" >Home</Link></li>
    <li class="breadcrumb-item"><Link to="/Catalogue" >Catalogue</Link></li>
    <li class="breadcrumb-item active" aria-current="page"> Sofa Type  </li>
  </ol>
</nav>
</div>
</div>
</div>
</div>
        <div className="album py-0">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
              
                    <div className="card-body">
            
                    <h3 className="card-text mb-1">     Top Sofa categories</h3>
                           
                            <p className="card-text">Genuine Product</p>
                    
                    </div>

                  
                </div>
                </div>
                </div>
                </div>
                <div className="album py-4">
        
        <div className="container " >

            <div className="row  ">
       
            <div className="col-md-6 mt-6  " >
                        <div className="card mb-4 border-White box shadow  ">
                            <Link to="/sofa">
                           
                        <img src={Sofa1} className="card-img-top "   alt="Sofa1" />
                      
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>L Shape Sofa</b></h6>
                                <Link to="/sofa">
                                <p className="card-text mb-3">Explore Now</p>   </Link>
                             <button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/sofa" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/sofa" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/sofa" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/sofa" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/sofa" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-6 " >
                        <div className="card mb-4 border box  shadow ">
                          <Link to="/club-sofa">
                        <img src={Chair2} className="card-img-top "   alt="Chair2" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b>English or Club Sofa</b></h6>
                                <Link to="/club-sofa">
                                <p className="card-text mb-3"> Explore Now</p></Link>
                                <button type="button" className="btn btn btn-light me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/club-sofa" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/club-sofa" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/club-sofa" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/club-sofa" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/club-sofa" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
<div className="d-flex justify-content-between align-items-center">
                                

               
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
        </>
    );
}

export default Sofatype;