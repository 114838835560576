
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyDTguKShaykqxIyleI7Gf340JZ6v8TxFYw",
    authDomain: "poly-age.firebaseapp.com",
    projectId: "poly-age",
    storageBucket: "poly-age.appspot.com",
    messagingSenderId: "1063722573663",
    appId: "1:1063722573663:web:0018a5618fdf00774260ea",
    measurementId: "G-JGC9Q1173R"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;