import React from 'react';
import './About.css'
import {Link } from 'react-router-dom';
import Navbar from '../inc/Navbar';
import Mapg from '../pages/Mapg';
import DirectorNigamSir from '../images/Director.jpg';

function About(props) {
    return (
<>
<Navbar/>
      <br/>
      <br/>
      <br/>
<div class="p-3 mb-2 bg-light text-dark">
<div className="album py-5">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >
                <div className="nisha1">
            <img src={DirectorNigamSir} className="images"   alt="DirectorNigamSir" />
            <div className="middle1">
    <div className="text1"> <h5 style={{color: "black"}}>I'm Deep Nigam, Founder of Poly Age</h5>
    </div>
  </div>
           
            </div>
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-8" >
             
                            <h6 className="card-text"> <b>SHREE DEEP PRAKASH NIGAM <svg xmlns="http://www.w3.org/2000/svg" style={{color:"blue"}} width="17" height="17" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
</svg><br/> Director and Co-Founder</b></h6>
                                <p className="card-text">
I have been in the business since more than 25 years now, Poly Age provides a wide range of products with the best quality and the
most reasonable prices. We have many happy customars nation wide and we are looking forward to keep them happy and smiling always forever.</p>
                            
           
                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            
                            <Mapg/>
</>

    );
}
export default About;