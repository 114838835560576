import React from 'react';

import {Link} from "react-router-dom";
import Navbar from "../inc/Navbar";
import Netback from "../images/sofacat.jpg";
import card2 from "../images/tablecat.jpg";
import Pdfsofa from "../images/sofa.pdf";
import Pdftable from "../images/table.pdf";

function Catalogue (props) {
    return (
<>
<Navbar/>
<br/>
<br/>
<div className="section footer bg-primary text-white">
    <br/>
    <br/>
    <br/>
  
   
    <div className="album py-1">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
              
                    <div className="card-body">
            
                           
                            <h1 className="card-text mb-1" > <small>CATALOGUE</small></h1>
                               
                    
                    </div>

                  
                </div>
                </div>
                </div>
                </div>
   

    <br/>
    <br/>
    
</div>
<div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/" >Home</Link></li>
    <li class="breadcrumb-item"><Link to="/Catalogue" >Catalogue</Link></li>
    <li class="breadcrumb-item active" aria-current="page"> View Catalogue  </li>
  </ol>
</nav>
</div>
</div>
</div>
</div>

<div className="album py-2">
               
               <div className="container">
       
                   <div className="row">
              
                   <div className="col-md-3 mt-6  " >
                               <div className="card mb-4 border-White box first  ">
                                   <Link to="/chair">
                                  
                               <img src={Netback} className="card-img-top "   alt="Netback" />
                             
                               </Link>
                                   <div className="card-body">
                                       
                                       <h6  style={{textAlign: "center", color: "DarkSlateBlue	"}} className="card-text"> <b>Sofa Catalogue</b></h6>
                                      <center>
                                      <a href ={Pdfsofa}>
                                       <button type="button" class="btn btn-outline-dark">Download Catalogue </button></a>
                                       </center>
                   
       
       
                                       <div className="d-flex justify-content-between align-items-center">
                                      
                                              
                               
                                     
                                   </div>
                                 
                                   </div>
                                   </div>
                                      
                                   </div>
                                   <div className="col-md-3 mt-6 " >
                               <div className="card mb-4 border box  first">
                                  <Link to="/chair">
                                  
                               <img src={card2} className="card-img-top "   alt="card2" /></Link>
                                   <div className="card-body">
                                       <h6 style={{textAlign: "center", color: "DarkSlateBlue	"}} className="card-text"><b>Table Catalogue</b></h6>
                                       <center>
                                        <a href={Pdftable}>
                                               <button type="button" class="btn btn-outline-dark">Download Catalogue</button></a>
</center>
                 
       <div className="d-flex justify-content-between align-items-center">
                                       
                                  
                                   </div>
                                   </div>
                                   </div>
                                      
                                   </div>
                                   {/* style={{textAlign: "center"}} */}
                                  
                            </div>
                                      
                                   </div>
                                   </div>
                                   
                                   
</>
    );
}

export default Catalogue;