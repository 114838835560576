import React from "react"
import Navbar from "../inc/Navbar";
import {Link } from "react-router-dom";
import King from "../images/king.jpg";
import Queen from "../images/queen.jpg";
import Single from "../images/pb.jpg";
import { 
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
     FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,
  
     LinkedinShareButton,
     LinkedinIcon,
     TwitterIcon,
  }  from "react-share";

function Bedtype(props) {
    return(
        <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/" >Home</Link></li>
    <li class="breadcrumb-item"><Link to="/Catalogue" >Catalogue</Link></li>
    <li class="breadcrumb-item active" aria-current="page"> Bed Types </li>
  </ol>
</nav>
</div>
</div>
</div>
</div>
        <div className="album py-0">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
              
                    <div className="card-body">
            
                    <h3 className="card-text mb-1">     Top Bed categories</h3>
                           
                            <p className="card-text">Genuine Product</p>
                    
                    </div>

                  
                </div>
                </div>
                </div>
                </div>
                <div className="album py-4">
        
        <div className="container " >

            <div className="row  ">
       
            <div className="col-md-4 mt-6  " >
                        <div className="card mb-4 border-White box shadow  ">
                            <Link to="/kingbed">
                           
                        <img src={King} className="card-img-top "   alt="King Bed" />
                      
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>King Bed</b></h6>
                                <Link to="/kingbed">
                                <p className="card-text mb-3">Explore Now</p>   </Link>
                             <button type="button" className="btn btn btn-primary me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/kingbed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/kingbed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/kingbed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/kingbed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/kingbed" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6 " >
                        <div className="card mb-4 border box  shadow ">
                          <Link to="/queenbed">
                        <img src={Queen} className="card-img-top "   alt="Queen bed" /></Link>
                            <div className="card-body">
                                <h6 style={{ color: "DarkSlateBlue	"}} className="card-text"><b>Queen Bed</b></h6>
                                <Link to="/queenbed">
                                <p className="card-text mb-3"> Explore Now</p></Link>
                                <button type="button" className="btn btn btn-primary me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/queenbed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/queenbed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/queenbed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/queenbed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/queenbed" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
<div className="d-flex justify-content-between align-items-center">
                                

               
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6  " >
                        <div className="card mb-4 border-White box shadow  ">
                            <Link to="/singlebed">
                           
                        <img src={Single} className="card-img-top "   alt="Single Bed" />
                      
                        </Link>
                            <div className="card-body">
                                
                                <h6  style={{ color: "DarkSlateBlue	"}} className="card-text"> <b>Single Bed</b></h6>
                                <Link to="/singlebed">
                                <p className="card-text mb-3">Explore Now</p>   </Link>
                                <button type="button" className="btn btn btn-primary me-1">
                                       Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> < FacebookShareButton url="https://www.polyage.in/singlebed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.polyage.in/singlebed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.polyage.in/singlebed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.polyage.in/singlebed" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>

    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.polyage.in/singlebed" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                               
                            </div>
                         
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
        </>
    );
}

export default Bedtype;