import React from 'react';
import {Link} from 'react-router-dom';

function Main(props) {
    return (
<>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            
 
           
         

            <iframe width="657" height="333" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=657&amp;height=333&amp;hl=en&amp;q=155,%20Lanin%20Sarani,%20Poly%20Age%20Kolkata+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
            </div>

</div>
</div>


</>
);
}

export default Main;